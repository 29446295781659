
import AvatarImage from "@/components/AvatarImage.vue";
import User from "@/interfaces/User";
import { acceptRequest, deleteFriend } from "@/services/relationshipService";
import { ChannelsModule } from "@/store/modules/channels";
import { PopoutsModule } from "@/store/modules/popouts";
import CustomButton from "@/components/CustomButton.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "FriendTemplate",
  components: { AvatarImage, CustomButton },
  props: {
    friend: {
      type: Object as PropType<{ recipient: User; status: number }>,
      required: true,
    },
    dmChannel: {
      type: Object as PropType<{ recipients: User[] }>,
      required: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    user(): any {
      if (this.friend) {
        return this.friend.recipient || this.friend;
      } else {
        return this.dmChannel?.recipients[0];
      }
    },
  },
  methods: {
    clickedEvent(event: any) {
      if (
        !event.target.closest(".avatar") &&
        !event.target.closest(".button")
      ) {
        ChannelsModule.LoadDmChannel(this.user.id);
      }
    },
    showProfile() {
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id: this.user.id },
      });
    },
    cancelOrDecline() {
      deleteFriend(this.user.id);
    },
    acceptFriend() {
      acceptRequest(this.user.id);
    },
  },
});
